<template>
  <div class="box">
    <div style="overflow:auto">
      <div
        id="report"
        :style="{
          width: this.source.length * 200 + 250 + 'px',
          height: '500px',
          margin: 0
        }"
      ></div>
    </div>
    <div class="sections section"></div>
  </div>
</template>

<script>
import echarts from "echarts";

export default {
  components: {},

  mixins: [],

  props: ["theme"],
  data() {
    return {
      source: [75, 100, 55, 80, 70],
      score: 100,
      charts: ""
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.$nextTick(function() {
      this.draw("report");
    });
  },

  methods: {
    showTitle(val) {
      return val;
    },
    draw(id) {
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "High School GPA",
          subtext: "SAT/ACT"
        },
        grid: {
          left: "3%",
          right: "7%",
          bottom: "7%",
          containLabel: true
        },
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          formatter: function(params) {
            if (params.value.length > 1) {
              return (
                params.seriesName +
                " :<br/>" +
                params.value[0] +
                " (SAT) " +
                params.value[1] +
                " (ACT) "
              );
            } else {
              return (
                params.seriesName +
                " :<br/>" +
                params.name +
                " : " +
                params.value +
                " (ACT) "
              );
            }
          },
          axisPointer: {
            show: true,
            type: "cross",
            lineStyle: {
              type: "dashed",
              width: 1
            }
          }
        },
        toolbox: {
          feature: {
            dataZoom: {},
            brush: {
              type: ["rect", "polygon", "clear"]
            }
          }
        },
        brush: {},
        legend: {
          data: ["Interested","Applied","Accepted","Enrolled", "Rejected","Waitlisted"],
          left: "center",
          bottom: 10
        },
        xAxis: [
          {
            type: "value",
            scale: true,
            axisLabel: {
              formatter: "{value}"
            },
            splitLine: {
              show: false
            },
            min: 800,
            max: 1600
          }
        ],
        yAxis: [
          {
            type: "value",
            scale: true,
            axisLabel: {
              formatter: "{value}"
            },
            splitLine: {
              show: false
            },
            min: 0,
            max: 4
          }
        ],
        series: [
          {
            name: "Interested",
            type: "scatter",
            emphasis: {
              focus: "series"
            },
            // prettier-ignore
            data: [[1450,3.2],[1550,3.8]]
          },
          {
            name: "Applied",
            type: "scatter",
            emphasis: {
              focus: "series"
            },
            // prettier-ignore
            data: [[1453,3.7],[1560,4]]
          },
          {
            name: "Accepted",
            type: "scatter",
            emphasis: {
              focus: "series"
            },
            // prettier-ignore
            data: [[1480,3.6],[1580,4]]
          },
          {
            name: "Enrolled",
            type: "scatter",
            emphasis: {
              focus: "series"
            },
            // prettier-ignore
            data: [[1534,3.4],[1590,3.3]]
          },
          {
            name: "Rejected",
            type: "scatter",
            emphasis: {
              focus: "series"
            },
            // prettier-ignore
            data: [[1450,3.8],[1500,3.2]]
          },
          {
            name: "Waitlisted",
            type: "scatter",
            emphasis: {
              focus: "series"
            },
            // prettier-ignore
            data: [[1470,3.1],[1566,3.7]]
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped></style>
