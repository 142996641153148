/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import moment from "moment";
export default {
  methods: {
    toThousands(num) {
      if (num === null) return null;
      num = (num || 0).toString();
      let number = 0,
        floatNum = "",
        intNum = "";
      // 判断是否有小数位，有则截取小数点后的数字
      if (num.indexOf(".") > 0) {
        number = num.indexOf("."); // 获取小数点出现的位置
        floatNum = num.substr(number); // 截取arr.substr(form, length)
        intNum = num.substring(0, number); // 截取arr.substring(start, end)
      } else {
        intNum = num;
      }
      let result = [],
        counter = 0;
      intNum = intNum.split("");
      // 利用3的倍数，向数组插入','
      for (let i = intNum.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(intNum[i]);
        if (!(counter % 3) && i != 0) {
          result.unshift(",");
        }
      }
      return result.join("") + floatNum || "";
    },
    showReport(val, str = "", str0 = "") {
      if (val !== null) {
        return str0 + val + str;
      } else {
        return "-";
      }
    },
    trimStr(str) {
      if (str) {
        return str.replace(/(^\s*)|(\s*$)/g, "");
      } else {
        return "";
      }
    },
    getMD(datetime) {
      const now = moment();
      const momentDatetime = moment(datetime);
      const diffHours = now.diff(momentDatetime, "hours");
      return moment(datetime).format("MMM DD");
    },
    showTitle(val) {
      // return this.$t("schools.Campus Life Section." + this.trimStr(val));
      return val;
    },
    getTitle(obj) {
      let keys = Object.keys(obj);
      let title = keys[0];
      return title;
    },
    getArr(obj) {
      let keys = Object.keys(obj);
      let title = keys[0];
      let val = obj[title];
      return val;
    },
    showContent(obj, type) {
      console.log(obj);
      let keys = Object.keys(obj);
      let title = keys[0];
      let val = obj[title];
      if (type === "title") {
        return title;
      }
      if (type === "content") {
        if (Array.isArray(val)) {
          let html = "";
          val.forEach(item => {
            return (html += `${item}\n`);
          });
          return html;
        } else {
          return val;
        }
      }
      console.log(val);
    }
  }
};
