<template>
  <div class="box">
    <div class="sections container">
      <div class="section row">
        <div class="col-sm-6">
          <div class="section-title">
            General Information
          </div>
          <p class="general-info">
            <span>School Type</span>
            <b>Private, Coed</b>
          </p>
          <p class="general-info">
            <span>Year Founded</span>
            <b>1746</b>
          </p>
          <p class="general-info">
            <span>Religious Affiliation</span>
            <b>None</b>
          </p>
          <p class="general-info">
            <span>Academic Calendar</span>
            <b>Semester</b>
          </p>
          <p class="general-info">
            <span>Setting</span>
            <b>Suburban</b>
          </p>
          <p class="general-info">
            <span>Phone</span>
            <b>(609) 258-3000</b>
          </p>
          <p class="general-info">
            <span>School Website</span>
            <b
              ><a href="" target="_blank"
                >Website
                <small><i class="fas fa-external-link-alt"></i></small></a
            ></b>
          </p>
        </div>
        <div class="col-sm-6">
          <div class="locationMap">
            <BaiduMap
              :center="center"
              :zoom="zoom"
              :scroll-wheel-zoom="true"
              class="bmView"
              ak="BnpYvwZd3AYaD5GGqSC7ywAXEZDkcDay"
            >
              <bm-marker
                :position="center"
                :dragging="false"
                :title="getData.name[lang]"
                animation="BMAP_ANIMATION_BOUNCE"
              >
              </bm-marker>
            </BaiduMap>
            <div class="locationAddress">
              <div class="section-title">
                <div>
                  {{ getData.name[lang] }}
                  {{ showTitle("Campus Map") }}
                </div>
              </div>
              <div>
                <h6>
                  <small style="color:red;margin-right:5px;font-size:16px"
                    ><i class="fas fa-map-marker"></i></small
                  >{{ getData.name[lang] }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="section">
        <div class="section-title">Princeton University's 2022 Rankings</div>
        <div v-if="getData.ranks.length > 0">
          <div
            class="content"
            v-for="(rank, index) in getData.ranks"
            :key="index"
          >
            <div class="content-title-value">
              <span class="text-warning" style="margin-right:5px"
                ><i class="fas fa-tag"></i
              ></span>
              <b style="margin-right:5px">#{{ rank.rank }}</b>
              in
              <b>
                <span v-if="rank.site_name">[{{ rank.site_name }}] </span>
                {{ rank.rank_year }}
                {{ rank.rank_name["en"] }}
              </b>
              <router-link
                :to="{
                  name: 'LandingPage',
                  params: { rank: rank.id }
                }"
                target="_blank"
              >
                <small
                  >(查看<b v-if="rank.site_name">{{ rank.site_name }}</b
                  >詳細排名)</small
                >
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="section">
        <div class="section-title">
          Princeton University Alumni Starting Salaries
        </div>
        <div
          class="content-title"
          style="display: flex; justify-content: space-between"
        >
          <span>Median starting salary of alumni</span>
          <b>$71,300</b>
        </div>
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="name" label="" width=""> </el-table-column>
          <el-table-column prop="val" label="" width=""> </el-table-column>
        </el-table>
      </div>
      <hr />
      <div class="section">
        <div class="section-title">
          Princeton University Admissions
        </div>
        <div class="content-title-value">
          Princeton University admissions is most selective with an acceptance
          rate of 6% and an early acceptance rate of 14.7%. Half the applicants
          admitted to Princeton University have an SAT score between 1450 and
          1570 or an ACT score of 32 and 35. However, one quarter of admitted
          applicants achieved scores above these ranges and one quarter scored
          below these ranges. The application deadline is Jan. 1 and the
          application fee at Princeton University is $70.
        </div>
        <p
          class="general-info"
          v-for="(item, index) in admissionsTableData"
          :key="index"
        >
          <span>{{ item.name }}</span>
          <b>{{ item.val }}</b>
        </p>
      </div>
      <hr />
      <div class="section">
        <div class="section-title">Will You Get Into Princeton University?</div>
        <div class="section-title">College Admissions Calculator</div>
        <ScatterTable :theme="theme" />
      </div>
      <hr />
      <div class="section">
        <div class="section-title">Academic Life at Princeton University</div>
        <div class="content-title-value">
          The student-faculty ratio at Princeton University is 4:1, and the
          school has 77.6% of its classes with fewer than 20 students. The most
          popular majors at Princeton University include: Social Sciences;
          Engineering; Computer and Information Sciences and Support Services;
          Biological and Biomedical Sciences; Public Administration and Social
          Service Professions; Physical Sciences; History; Foreign Languages,
          Literatures, and Linguistics; English Language and Literature/Letters;
          and Philosophy and Religious Studies. The average freshman retention
          rate, an indicator of student satisfaction, is 94%..
        </div>
        <p
          class="general-info"
          v-for="(item, index) in academicTableData"
          :key="index"
        >
          <span>{{ item.name }}</span>
          <b>{{ item.val }}</b>
        </p>
      </div>
      <hr />
      <div class="section">
        <div class="section-title">Student Life at Princeton University</div>
        <div class="content-title-value">
          Princeton University has a total undergraduate enrollment of 4,773
          (fall 2020), with a gender distribution of 50% male students and 50%
          female students. In sports, Princeton University is part of the NCAA
          I.
        </div>
        <p
          class="general-info"
          v-for="(item, index) in studentTableData"
          :key="index"
        >
          <span>{{ item.name }}</span>
          <b>{{ item.val }}</b>
        </p>
      </div>
      <hr />
      <div class="section">
        <div class="section-title">
          Princeton University Tuition & Financial Aid
        </div>
        <div class="content-title-value">
          At Princeton University, 62% of full-time undergraduates receive some
          kind of need-based financial aid, and the average need-based
          scholarship or grant award is $52,438. Paying for college doesn't have
          to be difficult or devastating. Go to the Paying for College knowledge
          center to get advice on raising cash and reducing costs, or use the
          U.S. News 529 Finder to choose the best tax-advantaged college
          investment account for you.
        </div>
        <BarTable :theme="theme" />
        <p
          class="general-info"
          v-for="(item, index) in tuitionTableData"
          :key="index"
        >
          <span>{{ item.name }}</span>
          <b>
            {{ item.val }}
            <small v-if="item.date" v-html="item.date"></small>
          </b>
        </p>
      </div>
      <hr />
      <div class="section">
        <div class="section-title">
          Campus Safety
        </div>
        <div class="content-title-value">
          Campus safety data were reported by the institution to the U.S.
          Department of Education and have not been independently verified. The
          numbers for criminal offenses reflect reports of alleged offenses to
          campus security and/or law enforcement authorities, not necessarily
          prosecutions or convictions. Experts advise prospective students and
          their families to do their own research to evaluate the safety of a
          campus as well as the surrounding area.
        </div>
      </div>
      <hr />
      <div class="section">
        <div class="section-title">
          Princeton University Campus
        </div>
        <div class="content-title-value">
          Princeton University offers a number of student services, including
          nonremedial tutoring, women's center, placement service, day care,
          health service, health insurance. Princeton University also offers
          campus safety and security services like 24-hour foot and vehicle
          patrols, late night transport/escort service, 24-hour emergency
          telephones, lighted pathways/sidewalks, controlled dormitory access
          (key, security card, etc.). Alcohol is permitted for students of legal
          age at Princeton University.
        </div>
        <p
          class="general-info"
          v-for="(item, index) in campusTableData"
          :key="index"
        >
          <span>{{ item.name }}</span>
          <b>
            {{ item.val }}
            <small v-if="item.date" v-html="item.date"></small>
          </b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker.vue";
import ScatterTable from "@/views/usNewsSchools/components/schoolComponents/ScatterTable";
import BarTable from "@/views/usNewsSchools/components/schoolComponents/BarTable";

export default {
  components: {
    BaiduMap,
    BmMarker,
    ScatterTable,
    BarTable
  },

  mixins: [mixin],

  props: ["getData", "lang", "theme"],
  data() {
    return {
      center: { lng: -99.707941, lat: 32.47108 },
      zoom: 15,
      tableData: [
        {
          name: "Computer and Information Sciences",
          val: "$99,400"
        },
        {
          name: "Engineering",
          val: "$76,000"
        },
        {
          name: "Philosophy and Religious Studies",
          val: "$70,700"
        },
        {
          name: "Social Sciences",
          val: "$70,200"
        },
        {
          name: "Physical Sciences",
          val: "$67,600"
        }
      ],
      admissionsTableData: [
        {
          name: "Selectivity",
          val: "Most selective"
        },
        {
          name: "Fall 2020 acceptance rate",
          val: "6%"
        },
        {
          name: "Application deadline",
          val: "Jan. 1"
        },
        {
          name: "SAT/ACT scores must be received by",
          val: "Jan. 1"
        },
        {
          name: "Priority application deadline",
          val: "N/A"
        }
      ],
      academicTableData: [
        {
          name: "Student-faculty ratio",
          val: "4:1"
        },
        {
          name: "4-year graduation rate",
          val: "90%"
        },
        {
          name: "Total faculty",
          val: "998 full time, 197 part time"
        }
      ],
      studentTableData: [
        {
          name: "Total enrollment",
          val: "7,852(fall 2020)"
        },
        {
          name: "Collegiate athletic association",
          val: "NCAA I"
        },
        {
          name: "Full-time degree-seeking students",
          val: "4,688"
        }
      ],
      tuitionTableData: [
        {
          name: "Tuition and fees",
          val: "$56,010",
          date: "(2021-22)"
        },
        {
          name: "Room and board",
          val: "$18,180",
          date: "(2021-22)"
        },
        {
          name:
            "Minimum credits per term a student can take for full-time tuition price",
          val: "N/A",
          date: null
        }
      ],
      campusTableData: [
        {
          name: "Students who have cars on campus",
          val: "N/A",
          date: null
        },
        {
          name: "Health insurance offered",
          val: "Yes",
          date: null
        },
        {
          name: "Nearest airports (miles)",
          val: "Newark (40) Philadelphia (45)",
          date: null
        }
      ]
    };
  },
  computed: {},
  watch: {
    getData(){
      this.center.lng = this.getData.longitude;
      this.center.lat = this.getData.latitude;
    }
  },

  mounted() {
    this.$nextTick(this.showTitle);
  },

  methods: {
    showTitle(val) {
      return val;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";

.header-cards {
  display: flex;
}
.header-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
  color: white;
}

.cards-item:not(:last-child) {
  margin-right: 8px;
}
.cards-item {
  min-width: 120px;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
}
.header-cards-phone .cards-item {
  width: 100%;
  margin-bottom: 20px;
}

.cards-item .item-body {
  height: 40px;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.11;
  letter-spacing: 0.19px;
}
.cards-item .item-text {
  padding-top: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  white-space: nowrap;
}
.header-cards-phone .cards-item .item-text {
  letter-spacing: normal;
}
.general-info {
  margin: 0;
  display: flex;
  justify-content: space-between;
  line-height: 50px;
  border-bottom: 1px solid rgb(211, 215, 220);
}
.locationMap {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fbf5f2;
  border-radius: 8px;
  overflow: hidden;
}
.bmView {
  width: 100%;
  flex: 1;
}
.locationAddress {
  padding: 0 16px;
  height: 100px;
}
hr {
  margin: 40px 0 0 0;
}
@media screen and (max-width: 768px) {
  .locationMap {
    height: 300px;
  }
}

</style>
