<template>
  <div class="box">
    <div class="sections section">
      <div v-if="getData.ranks.length > 0">
        <div
          class="content"
          v-for="(rank, index) in getData.ranks"
          :key="index"
        >
          <div class="content-title-value">
            <span class="text-warning" style="margin-right:5px"
              ><i class="fas fa-tag"></i
            ></span>
            <b style="margin-right:5px">#{{ rank.rank }}</b>
            in
            <b>
              <span v-if="rank.site_name">[{{ rank.site_name }}] </span>
              {{ rank.rank_year }}
              {{ rank.rank_name["en"] }}
            </b>
            <router-link
              :to="{
                name: 'LandingPage',
                params: { rank: rank.id }
              }"
              target="_blank"
            >
              <small>(查看<b v-if="rank.site_name">{{ rank.site_name }}</b>詳細排名)</small>
            </router-link>
          </div>
        </div>
      </div>
      <div v-else style="margin-top: 20px">
        {{ $t("schools.Not reported") }}
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
export default {
  components: {},

  mixins: [mixin],

  props: ["getData", "lang", "theme"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showTitle(val) {
      // return this.$t("schools.Campus Life Section." + this.trimStr(val));
      return val;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
</style>
