import { render, staticRenderFns } from "./BarTable.vue?vue&type=template&id=600c1e4e&scoped=true&"
import script from "./BarTable.vue?vue&type=script&lang=js&"
export * from "./BarTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "600c1e4e",
  null
  
)

export default component.exports