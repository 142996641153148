<template>
  <div>
    <el-collapse accordion>
      <el-collapse-item v-for="(item, index) in data" :key="index">
        <template slot="title">
          <div class="section-title">
            {{ getTitle(item) }}
          </div>
        </template>
        <div>
          <div
            class="content"
            v-for="(info, index) in getArr(item)"
            :key="index"
          >
            <div class="content-title-value">
              {{ getTitle(info) }}
            </div>
            <div class="content-value">
              <div>{{ showContent(info, "content") }}</div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import mixin from "@/views/usNewsSchools/mixins/index.js";

export default {
  components: {},

  mixins: [mixin],

  props: ["data"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showTitle(val) {
      return val;
    },
    getTitle(obj) {
      let keys = Object.keys(obj);
      let title = keys[0];
      return title;
    },
    getArr(obj) {
      let keys = Object.keys(obj);
      let title = keys[0];
      let val = obj[title];
      return val;
    },
    showContent(obj, type) {
      let keys = Object.keys(obj);
      let title = keys[0];
      let val = obj[title];
      if (type === "title") {
        return title;
      }
      if (type === "content") {
        if (Array.isArray(val)) {
          let html = "";
          val.forEach(item => {
            // html += `${item}\n`;
            if (Object.prototype.toString.call(item) === "[object Object]") {
              html += `${this.getTitle(item)}: ${this.showContent(item, type)}\n`;
            } else {
              html += `${item}\n`;
            }
          });
          return html;
        } else {
          return val;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/views/usNewsSchools/css/index.scss";
::v-deep .el-collapse-item__header {
  height: auto;
  line-height: unset;
}
</style>
