<template>
  <div class="box">
    <div class="sections section">
      {{ content }}
    </div>
    <div class="sections section">
      <Collapse :data="campusData.data"></Collapse>
      <el-collapse accordion v-if="false">
        <!-- Campus Info & Regulations -->
        <el-collapse-item v-for="(item, title, i) in campusData.data" :key="i">
          <template slot="title">
            <div class="section-title">
              {{ getTitle(item) }}
            </div>
          </template>
          <div>
            <div
              class="content"
              v-for="(info, index) in getArr(item)"
              :key="index"
            >
              <div class="content-title-value">
                {{ getTitle(info) }}
              </div>
              <div class="content-value">
                <div>{{ showContent(info, "content") }}</div>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <!-- Career Services -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Career Services") }}
            </div>
          </template>
          <template>
            <div class="content-title">
              {{ showTitle("Alumni Starting Salaries by Major") }}
            </div>
            <div class="content" v-for="(i, index) in 1" :key="index + 12">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 12], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 12], "content") }}
                </div>
              </div>
            </div>
            <div class="content-title">
              {{ showTitle("Alumni Early and Midcareer Salaries") }}
            </div>
            <div class="content" v-for="(i, index) in 1" :key="index + 13">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 13], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 13], "content") }}
                </div>
              </div>
            </div>
            <div class="content-title">
              {{ showTitle("Career Services") }}
            </div>
            <div class="content" v-for="(i, index) in 3" :key="index + 14">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 14], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 14], "content") }}
                </div>
              </div>
            </div>
            <div class="content-title">
              {{ showTitle("Placement Statistics") }}
            </div>
            <div class="content" v-for="(i, index) in 1" :key="index + 21">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 21], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 21], "content") }}
                </div>
              </div>
            </div>
          </template>
        </el-collapse-item>
        <!-- Computer Resources -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Computer Resources") }}
            </div>
          </template>

          <template>
            <div class="content-title">
              {{ showTitle("Computer Availability") }}
            </div>
            <div class="content" v-for="(i, index) in 2" :key="index + 22">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 22], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 22], "content") }}
                </div>
              </div>
            </div>
            <div class="content-title">
              {{ showTitle("Tools & Services") }}
            </div>
            <div class="content" v-for="(i, index) in 2" :key="index + 24">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 24], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 24], "content") }}
                </div>
              </div>
            </div>
          </template>
        </el-collapse-item>
        <!-- Library & Museum Information -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Library & Museum Information") }}
            </div>
          </template>
          <template>
            <div class="content-title">
              {{ showTitle("Libraries") }}
            </div>
            <div class="content" v-for="(i, index) in 2" :key="index + 26">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 26], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 26], "content") }}
                </div>
              </div>
            </div>
            <div class="content-title">
              {{ showTitle("Museums") }}
            </div>
            <div class="content" v-for="(i, index) in 1" :key="index + 28">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 28], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 28], "content") }}
                </div>
              </div>
            </div>
          </template>
        </el-collapse-item>
        <!-- Learning Disabled (LD) Student Services -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Learning Disabled (LD) Student Services") }}
            </div>
          </template>
          <template>
            <div class="content-title">
              {{ showTitle("Program Information") }}
            </div>
            <div class="content" v-for="(i, index) in 5" :key="index + 29">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 29], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 29], "content") }}
                </div>
              </div>
            </div>
            <div class="content-title">
              {{ showTitle("Services Available") }}
            </div>
            <div class="content" v-for="(i, index) in 6" :key="index + 34">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 34], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 34], "content") }}
                </div>
              </div>
            </div>
            <div class="content-title">
              {{ showTitle("Contact Information") }}
            </div>
            <div class="content" v-for="(i, index) in 1" :key="index + 40">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 40], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 40], "content") }}
                </div>
              </div>
            </div>
          </template>
        </el-collapse-item>
        <!-- Physically Disabled Student Services -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Physically Disabled Student Services") }}
            </div>
          </template>
          <template>
            <div class="content-title">
              {{ showTitle("Services") }}
            </div>
            <div class="content" v-for="(i, index) in 2" :key="index + 41">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 41], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 41], "content") }}
                </div>
              </div>
            </div>
          </template>
        </el-collapse-item>
        <!-- Autism Spectrum Disorder (ASD) Student Services -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Autism Spectrum Disorder (ASD) Student Services") }}
            </div>
          </template>
          <template>
            <div class="content-title">
              {{ showTitle("Services") }}
            </div>
            <div class="content" v-for="(i, index) in 6" :key="index + 43">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 43], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 43], "content") }}
                </div>
              </div>
            </div>
          </template>
        </el-collapse-item>
        <!-- Attention-Deficit/Hyperactivity Disorder (ADHD) Student Services -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{
                showTitle(
                  "Attention-Deficit/Hyperactivity Disorder (ADHD) Student Services"
                )
              }}
            </div>
          </template>
          <template>
            <div class="content-title">
              {{ showTitle("Services") }}
            </div>
            <div class="content" v-for="(i, index) in 6" :key="index + 49">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 49], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 49], "content") }}
                </div>
              </div>
            </div>
          </template>
        </el-collapse-item>
        <!-- International Student Services -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("International Student Services") }}
            </div>
          </template>
          <template>
            <div class="content-title">
              {{ showTitle("Services") }}
            </div>
            <div class="content" v-for="(i, index) in 1" :key="index + 55">
              <div class="content-title-value">
                {{ showContent(campusData.data[index + 55], "title") }}
              </div>
              <div class="content-value">
                <div>
                  {{ showContent(campusData.data[index + 55], "content") }}
                </div>
              </div>
            </div>
          </template>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="sections section">
      <div class="section-title">
        {{ campusData.name }}
        {{ showTitle("Campus Map") }}
      </div>
      <div class="locationMap">
        <BaiduMap
          :center="center"
          :zoom="zoom"
          :scroll-wheel-zoom="true"
          class="bmView"
          ak="BnpYvwZd3AYaD5GGqSC7ywAXEZDkcDay"
        >
          <bm-marker
            :position="center"
            :dragging="false"
            :title="name"
            animation="BMAP_ANIMATION_BOUNCE"
          >
          </bm-marker>
        </BaiduMap>
        <div class="locationAddress">
          <div class="section-title">
            <div>
              {{ showTitle("School Location") }}
            </div>
          </div>
          <hr />
          <div style="margin-top:20px">
            <h6>
              <small style="color:red;margin-right:5px;font-size:16px"
                ><i class="fas fa-map-marker"></i></small
              >{{ name }}
            </h6>
            <div>
              <div>{{ showReport(getData.ao_address) }}</div>
              <div>
                {{ showReport(getData.ao_city) }},
                {{ showReport(getData.ao_state) }}, {{ getData.ao_zipcode1 }}-{{
                  getData.ao_zipcode2
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="sections section mt-4">
      <CampusLife :getData="getData" :lang="lang" :theme="theme" :name="name" />
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker.vue";
import Collapse from "@/views/usNewsSchools/components/schoolComponents/Collapse";
import CampusLife from "@/views/schools/components/CampusLife";
export default {
  components: {
    BaiduMap,
    BmMarker,
    Collapse,
    CampusLife
  },

  mixins: [mixin],

  props: ["getData", "campusData", "lang", "theme", "name"],
  data() {
    return {
      content:
        "Princeton University offers a number of student services, including nonremedial tutoring, women's center, placement service, day care, health service, health insurance. Princeton University also offers campus safety and security services like 24-hour foot and vehicle patrols, late night transport/escort service, 24-hour emergency telephones, lighted pathways/sidewalks, controlled dormitory access (key, security card, etc.). Alcohol is permitted for students of legal age at Princeton University.",
      center: { lng: -99.707941, lat: 32.47108 },
      zoom: 15
    };
  },
  computed: {},
  watch: {
    getData(){
      this.center.lng = this.getData.longitude;
      this.center.lat = this.getData.latitude;
    }
  },

  created() {},

  methods: {
    showTitle(val) {
      // return this.$t("schools.Campus Life Section." + this.trimStr(val));
      return val;
    },
    getTitle(obj) {
      let keys = Object.keys(obj);
      let title = keys[0];
      return title;
    },
    getArr(obj) {
      let keys = Object.keys(obj);
      let title = keys[0];
      let val = obj[title];
      return val;
    },
    showContent(obj, type) {
      console.log(obj);
      let keys = Object.keys(obj);
      let title = keys[0];
      let val = obj[title];
      if (type === "title") {
        return title;
      }
      if (type === "content") {
        if (Array.isArray(val)) {
          let html = "";
          val.forEach(item => {
            return (html += `${item}\n`);
          });
          return html;
        } else {
          return val;
        }
      }
      console.log(val);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
.locationMap {
  margin: 20px 0;
  display: flex;
  background: #fbf5f2;
  border-radius: 8px;
  overflow: hidden;
}
.bmView {
  width: 65%;
  height: 400px;
}
.locationAddress {
  width: 35%;
  padding: 0 16px 10px;
}
@media screen and (max-width: 768px) {
  .locationMap {
    flex-direction: column-reverse;
  }
  .bmView,
  .locationAddress {
    width: 100%;
  }
}
</style>
