<template>
  <div class="school" :style="appStyle" v-if="styleColor.color">
    <div class="school-header container">
      <div class="container">
        <h1 class="school-title">
          <!-- {{ school.name[langList[lang.value]] }} -->
          <span v-if="lang.value === 'EN-US'">{{
            school.name[langList[lang.value]]
          }}</span>
          <span v-else
            >{{ school.name[langList[lang.value]] }}&nbsp;({{
              school.name["en"]
            }})</span
          >
          <span class="school-subtitle" v-if="school.ranks.length > 0">
            <!-- PC端 -->
            <div class="d-none d-md-block">
              <div v-for="rank in school.ranks.slice(0, 1)" :key="rank.id">
                <el-tag type="info" class="ranks">
                  <span class="text-warning" style="margin-right:5px"
                    ><i class="fas fa-tag"></i
                  ></span>
                  <span>
                    <span v-if="rank.site_name">[{{ rank.site_name }}] </span>
                    <b>#{{ rank.rank }}</b> in
                    <a :href="'/schools?rank=' + rank.id" target="_blank"
                      ><b>{{ rank.rank_year }} {{ rank.rank_name["en"] }}</b></a
                    ></span
                  >
                </el-tag>
              </div>
              <router-link
                :to="{ path: `/schools/${schoolId}/ranks` }"
                style="display: inline-block;margin-top:10px;font-size:16px;color:white !important"
              >
                <el-button type="success" size="mini" plain>
                  {{ $t("schools.More Rankings") }}
                </el-button>
              </router-link>
            </div>
            <!-- 手机端 -->
            <div class="d-block d-md-none">
              <el-collapse accordion>
                <el-collapse-item>
                  <template slot="title">
                    <span class="text-warning" style="margin-right:5px"
                      ><i class="fas fa-tag"></i
                    ></span>
                    {{ $t("schools.ranks") }}
                  </template>
                  <div
                    type="info"
                    class="ranks"
                    v-for="rank in school.ranks"
                    :key="rank.id"
                  >
                    <span class="text-warning" style="margin-right:5px"
                      ><i class="fas fa-tag"></i
                    ></span>
                    <span>
                      <span v-if="rank.site_name"
                        >[ {{ rank.site_name }} ]
                      </span>
                      <b>#{{ rank.rank }}</b> in
                      <a :href="'/schools?rank=' + rank.id" target="_blank"
                        ><b
                          >{{ rank.rank_year }} {{ rank.rank_name["en"] }}</b
                        ></a
                      ></span
                    >
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </span>
        </h1>
      </div>
    </div>
    <div class="container us-news-container">
      <el-tabs v-model="activeName">
        <el-tab-pane name="overview">
          <span slot="label">
            <i class="fas fa-list"></i>
            {{ $t("schools.overview") }}
          </span>
          <Overview
            v-if="data && activeName === 'overview'"
            :getData="data"
            :lang="langList[lang.value]"
            :theme="styleColor.theme"
          />
        </el-tab-pane>
        <el-tab-pane name="ranks">
          <span slot="label">
            <i class="fas fa-chart-bar"></i>
            {{ $t("schools.ranks") }}
          </span>
          <Ranks
            v-if="data && activeName === 'ranks'"
            :getData="data"
            :lang="langList[lang.value]"
            :theme="styleColor.theme"
          />
        </el-tab-pane>
        <el-tab-pane name="admissions">
          <span slot="label">
            <i class="fas fa-graduation-cap"></i>
            {{ $t("schools.admissions") }}
          </span>
          <Admissions
            v-if="
              usNewsData &&
                usNewsData.us_news_admission &&
                activeName === 'admissions'
            "
            :getData="data"
            :lang="langList[lang.value]"
            :admissionsData="usNewsData.us_news_admission"
            :theme="styleColor.theme"
            :name="school.name[langList[lang.value]]"
          />
        </el-tab-pane>
        <el-tab-pane name="academic">
          <span slot="label">
            <i class="fas fa-file-alt"></i>
            {{ $t("schools.academic") }}
          </span>
          <Academic
            v-if="data && activeName === 'academic'"
            :getData="data"
            :lang="langList[lang.value]"
            :theme="styleColor.theme"
          />
        </el-tab-pane>
        <el-tab-pane name="students">
          <span slot="label">
            <i class="fas fa-users"></i>
            {{ $t("schools.students") }}
          </span>
          <Students
            v-if="data && activeName === 'students'"
            :getData="data"
            :studentsData="usNewsData.us_news_student_life"
            :lang="langList[lang.value]"
            :theme="styleColor.theme"
          />
        </el-tab-pane>
        <el-tab-pane name="financials">
          <span slot="label">
            <i class="fas fa-search-dollar"></i>
            {{ $t("schools.financials") }}
          </span>
          <Financials
            v-if="data && activeName === 'financials'"
            :financialsData="usNewsData.us_news_expense"
            :getData="data"
            :lang="langList[lang.value]"
            :theme="styleColor.theme"
          />
        </el-tab-pane>
        <el-tab-pane name="campus-life">
          <span slot="label">
            <i class="fas fa-university"></i>
            {{ $t("schools.campus-life") }}
          </span>
          <CampusLife
            v-if="
              usNewsData &&
                usNewsData.us_news_campus &&
                activeName === 'campus-life'
            "
            :getData="data"
            :campusData="usNewsData.us_news_campus"
            :lang="langList[lang.value]"
            :theme="styleColor.theme"
            :name="school.name[langList[lang.value]]"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="container skip-button">
      <div>
        <el-button type="primary" @click="activeName = PreviousSection">
          <i class="fas fa-angle-left"></i>
          Previous: {{ $t(`schools.${PreviousSection}`) }}
        </el-button>
      </div>
      <div>
        <el-button type="primary" @click="activeName = NextSection">
          Next: {{ $t(`schools.${NextSection}`) }}
          <i class="fas fa-angle-right"></i>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import schoolApi from "@/views/usNewsSchools/apis/school.js";
import Overview from "@/views/usNewsSchools/components/Overview";
import Admissions from "@/views/usNewsSchools/components/Admissions";
import Financials from "@/views/usNewsSchools/components/Financials";
import Academic from "@/views/usNewsSchools/components/Academic";
import CampusLife from "@/views/usNewsSchools/components/CampusLife";
import Students from "@/views/usNewsSchools/components/Students";
import Ranks from "@/views/usNewsSchools/components/Ranks";
import _ from "lodash";

export default {
  metaInfo() {
    return {
      title: this.school.name[this.langList[this.lang.value]]
        ? this.school.name[this.langList[this.lang.value]] +
          " - Ivy-Way Academy"
        : "Ivy-Way Academy"
    };
  },

  components: {
    Overview,
    Admissions,
    Financials,
    Academic,
    CampusLife,
    Students,
    Ranks
  },

  mixins: [],

  props: [],
  data() {
    return {
      schoolId: null,
      school: {
        name: "Abilene Christian University",
        ranks: []
      },
      styleColor: {
        theme: undefined,
        color: undefined
      },
      langList: {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      },
      data: null,
      activeName: "overview",
      usNewsData: null,
      sections: [
        "overview",
        "ranks",
        "admissions",
        "academic",
        "students",
        "financials",
        "campus-life"
      ]
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    appStyle() {
      return `--theme: ${this.styleColor.theme};--color: ${this.styleColor.color};`;
    },
    PreviousSection() {
      let index = this.sections.indexOf(this.activeName);
      let maxIndex = this.sections.length - 1;
      if (index === 0) {
        index = maxIndex;
      } else {
        index--;
      }
      return this.sections[index];
    },
    NextSection() {
      let index = this.sections.indexOf(this.activeName);
      let maxIndex = this.sections.length - 1;
      if (index === maxIndex) {
        index = 0;
      } else {
        index++;
      }
      return this.sections[index];
    }
  },
  watch: {
    activeName() {
      this.getSchoolSection();
      // this.$router.replace({
      //   name: "School",
      //   query: {
      //     section: this.activeName
      //   }
      // });
    }
  },

  mounted() {
    this.getSchoolSection();
  },

  methods: {
    async getSchoolSection() {
      this.data = null;
      let activeName = this.activeName;
      // activeName = "overview";
      if (this.activeName === "ranks") {
        activeName = "overview";
      }
      await this.setSchoolId();
      this.data = await schoolApi.getSection(this.schoolId, activeName);
      const school = await schoolApi.getSchool(this.schoolId);
      this.usNewsData = school.overview.us_news_institution
        ? school.overview.us_news_institution
        : null;
      this.school.name = school.overview.name;
      this.school.ranks = _.orderBy(
        school.overview.ranks,
        ["rank", "rank_year", "rank_name.en"],
        ["asc", "desc", "asc"]
      );
      if (school.overview.color !== null && school.overview.color !== "") {
        this.styleColor.theme = "#" + school.overview.color;
        this.styleColor.color = "#" + school.overview.color;
      } else {
        this.styleColor.theme = "#42a16a";
        this.styleColor.color = "#42a16a";
      }
    },
    // changeActiveName(tab,event){
    //   console.log(this.activeName);
    // },
    async setSchoolId() {
      const {
        school: { slug }
      } = await schoolApi.getSchoolCurrentSlug(this.$route.params.schoolId);
      this.schoolId = slug;
      if (this.$route.params.schoolId !== slug) {
        const newPath = this.$route.path.replace(
          this.$route.params.schoolId,
          slug
        );
        this.$router.replace({ path: newPath });
      }
    },
    viewDetails(key) {
      this.$router.replace({
        path: `/schools/${this.schoolId}/${key}`
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-tabs__item.is-active,
::v-deep .el-tabs__item:hover {
  color: var(--theme);
}
.us-news-container {
  background: white;
  padding-left: 40px;
  padding-right: 40px;
}

::v-deep .el-tabs__active-bar {
  background-color: var(--theme);
}
::v-deep .el-button--success,
::v-deep .el-button--primary {
  background: var(--theme);
  border-color: var(--theme);
}
::v-deep .el-button--success.is-plain,
::v-deep .el-button--primary.is-plain {
  color: var(--theme);
  background: white;
  border-color: var(--theme);
}

.school {
  background-color: #f3f3f1;
  padding: 40px 0;
}
::v-deep .el-tabs__header {
  margin-bottom: 20px;
}
.skip-button {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
}
.school h1,
.school h2,
.school h3,
.school h4,
.school h5,
.school h6,
.school p {
  color: var(--color);
}
.ranks {
  display: inline-block;
  border: 1px solid #fff;
  font-size: 16px;
  margin: 5px 0;
}
.container >>> .el-menu--horizontal > .el-menu-item.is-active {
  color: var(--color);
}
.container >>> .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.container >>> .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  color: var(--color);
}
.container >>> .el-menu.el-menu--horizontal {
  border-bottom: none;
  margin-bottom: 2px;
}
.container >>> .el-menu--horizontal > .el-menu-item {
  font-weight: 500;
  padding: 0;
  margin-right: 40px;
}
.school-header {
  padding: 20px 40px 20px 40px;
  background-color: var(--theme);
}
.school-header >>> .el-tabs__item {
  height: 60px;
  line-height: 60px;
}
.school-header >>> .el-tabs__item.is-active {
  color: var(--color);
}
.school-header >>> .el-tabs__nav-wrap::after {
  background-color: var(--theme);
}
.school-header >>> .el-tabs__item:hover {
  color: var(--color);
}
.school-header >>> .el-tabs__active-bar {
  background-color: var(--color);
}
.container >>> .el-menu--horizontal > .el-menu-item.is-active {
  border-color: var(--theme);
}
.school .school-title {
  font-weight: 300;
  color: white;
}
.school-subtitle {
  display: block;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: 0.63px;
  margin-top: 8px;
}
@media screen and (max-width: 768px) {
  .school {
    padding: 0;
    padding-bottom: 20px;
  }
  .school-header {
    padding: 20px 0 10px 0;
  }
  .us-news-container {
    background: white;
    padding-left: 15px;
    padding-right: 15px;
  }
  .skip-button {
    padding-left: 10px;
    padding-right: 10px;
  }
  h1,
  .h1 {
    font-size: 2rem;
  }
  .ranks {
    width: 100%;
    overflow: auto;
    font-size: 14px;
    margin: 0;
    margin-top: 5px;
    background-color: #f3f3f3;
    border-color: #e6e6e6;
    color: #838383;
    border-radius: 4px;
    padding: 0 10px;
    /* height: 32px; */
  }
  .sections {
    width: 100%;
    overflow: auto;
  }
  .sections-btns {
    margin: 10px 0 5px 0;
    white-space: nowrap;
    color: #909399;
  }
  .icon-title {
    padding-bottom: 5px;
    display: inline-block;
    min-width: 60px;
    font-size: 14px;
    text-align: center;
  }
  .icon-title:not(:last-child) {
    margin-right: 20px;
  }
  .icon-title .btn-logo {
    font-size: 22px;
  }
  .icon-title.active {
    color: var(--theme);
    border-bottom: 2px solid var(--theme);
  }
  .school-subtitle >>> .el-collapse-item__header {
    background-color: var(--theme);
    color: white;
    font-size: 14px;
    height: 30px;
    border: none;
  }
  .school-subtitle >>> .el-collapse {
    border: none;
  }
  .school-subtitle >>> .el-collapse-item__content {
    background-color: var(--theme);
    padding-bottom: 0;
  }
  .school-subtitle >>> .el-collapse-item__wrap {
    border: none;
  }
}
</style>
