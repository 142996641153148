<template>
  <div class="box">
    <div class="section sections">
      {{ content }}
    </div>
    <div class="section sections">
      <div class="section-title">Princeton University Admissions Stats</div>
      <div class="row">
        <div class="col-sm-4">
          <div class="subTitle">
            <div class="subTitle-title">APPLICATION DEADLINE</div>
            <div class="subTitle-content">Jan. 1</div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="subTitle">
            <div class="subTitle-title">APPLICATION FEE</div>
            <div class="subTitle-content">$70</div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="subTitle">
            <div class="subTitle-title">ACCEPTANCE RATE</div>
            <div class="subTitle-content">6%</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sections section">
      <div class="section-title">Will You Get Into Princeton University?</div>
      <div class="content-title-value">
        This free college acceptance calculator plots your test scores and GPA
        against other students' data to give you an idea of how you stack up as
        an applicant.
      </div>
    </div>
    <div class="sections section">
      <div class="admissions-calculator">
        <div class="section-title mb-0">College Admissions Calculator</div>
        <div class="content-title-value  mb-4">
          See how you compare against other Princeton University applicants
        </div>
        <ScatterTable :theme="theme" />
      </div>
    </div>
    <div class="sections section">
      <div class="section-title">
        Princeton University Admissions Requirements
      </div>
      <Collapse :data="admissionsData.data"></Collapse>
      <el-collapse accordion v-if="false">
        <el-collapse-item
          v-for="(item, index) in admissionsData.data"
          :key="index"
        >
          <template slot="title">
            <div class="section-title">
              {{ getTitle(item) }}
            </div>
          </template>
          <div>
            <div
              class="content"
              v-for="(info, index) in getArr(item)"
              :key="index"
            >
              <div class="content-title-value">
                {{ getTitle(info) }}
              </div>
              <div class="content-value">
                <div>{{ showContent(info, "content") }}</div>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <!-- Admissions Requirements -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Admissions Requirements") }}
            </div>
          </template>
        </el-collapse-item>
        <!-- Applications -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Applications") }}
            </div>
          </template>
        </el-collapse-item>
        <!-- Entering Class Stats -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Entering Class Stats") }}
            </div>
          </template>
        </el-collapse-item>
        <!-- Transfer Students -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Transfer Students") }}
            </div>
          </template>
        </el-collapse-item>
        <!-- International Students -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("International Students") }}
            </div>
          </template>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="sections section">
      <Admissions
        v-if="getData"
        :getData="getData"
        :lang="lang"
        :theme="theme"
        :name="name"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
import ScatterTable from "@/views/usNewsSchools/components/schoolComponents/ScatterTable";
import Collapse from "@/views/usNewsSchools/components/schoolComponents/Collapse";
import Admissions from "@/views/schools/components/Admissions";

export default {
  components: {
    ScatterTable,
    Collapse,
    Admissions
  },

  mixins: [mixin],

  props: ["getData", "lang", "admissionsData", "theme", "name"],
  data() {
    return {
      content:
        "Princeton University admissions is most selective with an acceptance rate of 6% and an early acceptance rate of 14.7%. Half the applicants admitted to Princeton University have an SAT score between 1450 and 1570 or an ACT score of 32 and 35. However, one quarter of admitted applicants achieved scores above these ranges and one quarter scored below these ranges. The application deadline is Jan. 1 and the application fee at Princeton University is $70.Admissions officials at Princeton University consider a student's GPA a very important academic factor. An applicant's high school class rank, when available, is considered very important and letters of recommendation are considered very important for admissions officials at Princeton University. To see additional academic factors along with other school data, learn more about College Compass.",
      activeName: "Undergraduate",
      center: { lng: -99.707941, lat: 32.47108 },
      zoom: 15
    };
  },
  computed: {},
  watch: {
    getData(){
      this.center.lng = this.getData.longitude;
      this.center.lat = this.getData.latitude;
    }
  },
  created() {
  
  },
  mounted() {},

  methods: {
    showTitle(val) {
      // return this.$t("schools.Campus Life Section." + this.trimStr(val));
      return val;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
.cards-item {
  height: 250px;
}
.locationMap {
  display: flex;
  background: var(--color);
  border-radius: 8px;
  overflow: hidden;
  color: white;
}
.locationMap hr {
  border-color: white;
}
.locationMap h6 {
  color: white;
}
.bmView {
  width: 65%;
  height: 300px;
}
.locationAddress {
  width: 35%;
  padding: 0 16px 10px;
}
@media screen and (max-width: 768px) {
  .locationMap {
    flex-direction: column-reverse;
  }
  .bmView,
  .locationAddress {
    width: 100%;
  }
}
</style>
