<template>
  <div class="box">
    <div style="overflow:auto">
      <div
        id="bar"
        :style="{
          width: this.source.length * 200 + 250 + 'px',
          height: '500px',
          margin: 0
        }"
      ></div>
    </div>
    <div class="sections section"></div>
  </div>
</template>

<script>
import echarts from "echarts";

export default {
  components: {},

  mixins: [],

  props: ["theme"],
  data() {
    return {
      source: [75, 100, 55, 80, 70],
      score: 100,
      charts: ""
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.$nextTick(function() {
      this.draw("bar");
    });
  },

  methods: {
    showTitle(val) {
      return val;
    },
    draw(id) {
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: "category",
          data: [
            "Applied for need-based aid",
            "Need was fully met",
            "Received need-based financial aid",
            "Received need-based self-help aid",
            "Average percent of need met"
          ]
        },
        yAxis: {
          type: "value",
          max: 100,
          axisLabel: {
            formatter: "{value}%"
          }
        },
        axisLabel: {
          color: "rgba(95,112,132,1)",
          fontSize: "14",
          interval: 0,
          formatter: function(params) {
            var newParamsName = ""; // 最终拼接成的字符串
            var paramsNameNumber = params.length; // 实际标签的个数
            var provideNumber = 15; // 每行能显示的字的个数
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
            /**
             * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
             */
            // 条件等同于rowNumber>1
            if (paramsNameNumber > provideNumber) {
              /** 循环每一行,p表示行 */
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = ""; // 表示每一次截取的字符串
                var start = p * provideNumber; // 开始截取的位置
                var end = start + provideNumber; // 结束截取的位置
                // 此处特殊处理最后一行的索引值
                if (p == rowNumber - 1) {
                  // 最后一次不换行
                  tempStr = params.substring(start, paramsNameNumber);
                } else {
                  // 每一次拼接字符串并换行
                  tempStr = params.substring(start, end) + "\n";
                }
                newParamsName += tempStr; // 最终拼成的字符串
              }
            } else {
              // 将旧标签的值赋给新标签
              newParamsName = params;
            }
            //将最终的字符串返回
            return newParamsName;
          }
        },
        series: [
          {
            data: this.source,
            type: "bar",
            barWidth: 120,
            itemStyle: { color: this.theme }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped></style>
