<template>
  <div class="box">
    <div class="sections section">
      {{ content }}
    </div>
    <div class="sections section">
      <Collapse :data="financialsData.data"></Collapse>
      <el-collapse accordion v-if="false">
        <!-- Tuition & Expenses -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Tuition & Expenses") }}
            </div>
          </template>
        </el-collapse-item>
        <!-- Financial Aid Statistics -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Financial Aid Statistics") }}
            </div>
          </template>
        </el-collapse-item>
        <!-- Student Loan Debt -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Student Loan Debt") }}
            </div>
          </template>
        </el-collapse-item>
        <!-- Scholarships -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Scholarships") }}
            </div>
          </template>
        </el-collapse-item>
        <!-- Jobs -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Jobs") }}
            </div>
          </template>
        </el-collapse-item>
      </el-collapse>
    </div>
    <hr />
    <div class="sections section mt-4">
      <Financials :getData="getData" :lang="lang" :theme="theme" />
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
import Collapse from "@/views/usNewsSchools/components/schoolComponents/Collapse";
import Financials from "@/views/schools/components/Financials";

export default {
  components: {
    Collapse,
    Financials
  },

  mixins: [mixin],

  props: ["getData", "financialsData", "lang", "theme"],
  data() {
    return {
      content: `Princeton University’s tuition is $56,010. Compared with the national average cost of tuition of $41,281, Princeton University is more expensive.
These figures include both tuition and fees, also referred to as the sticker price. Fees differ by institution and may fund library services, student gym facilities, student centers, technology resources and campus health centers.
As you’re comparing costs of different institutions, also consider the total cost and the net price. The total cost is the sticker price, plus the cost of room and board, books and supplies, and transportation and personal expenses. At Princeton University, the total cost is $76,010. The net price is the average cost of the university after aid and scholarship funds are discounted from the total cost, which comes in at $23,572 for the average student receiving need-based aid.`
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showTitle(val) {
      return val;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
</style>
