<template>
  <div class="box">
    <div class="section sections">
      {{ content }}
    </div>
    <div class="section sections">
      <div class="section-title mb-0">
        Find Your Ideal Major, College or Career
      </div>
      <div class="content-title-value">
        Discover the perfect major for you based on your innate wiring. The
        Innate Assessment sets you up for success by pairing you with majors,
        colleges and careers that fit your unique skills and abilities.
      </div>
    </div>
    <div class="section sections">
      <Collapse v-if="false" />
      <el-collapse accordion style="margin: 20px 0">
        <!-- Majors -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Majors") }}
            </div>
          </template>
        </el-collapse-item>
        <!-- Academic Programs & Offerings -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Academic Programs & Offerings") }}
            </div>
          </template>
        </el-collapse-item>
        <!-- Faculty & Classes -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Faculty & Classes") }}
            </div>
          </template>
        </el-collapse-item>
        <!-- Graduation & Retention -->
        <el-collapse-item>
          <template slot="title">
            <div class="section-title">
              {{ showTitle("Graduation & Retention") }}
            </div>
          </template>
        </el-collapse-item>
      </el-collapse>
    </div>
    <hr />
    <div class="sections section mt-4">
      <Academic :getData="getData" :lang="lang" :theme="theme" />
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
import Collapse from "@/views/usNewsSchools/components/schoolComponents/Collapse";
import Academic from "@/views/schools/components/Academic";

export default {
  components: { Collapse, Academic },

  mixins: [mixin],

  props: ["getData", "lang", "theme"],
  data() {
    return {
      content:
        "The student-faculty ratio at Princeton University is 4:1, and the school has 77.6% of its classes with fewer than 20 students. The most popular majors at Princeton University include: Social Sciences; Engineering; Computer and Information Sciences and Support Services; Biological and Biomedical Sciences; Public Administration and Social Service Professions; Physical Sciences; History; Foreign Languages, Literatures, and Linguistics; English Language and Literature/Letters; and Philosophy and Religious Studies. The average freshman retention rate, an indicator of student satisfaction, is 94%."
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showTitle(val) {
      return val;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
</style>
